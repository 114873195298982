const configs = {
  //API_URL: "https://api.propex.ai/live",

  //API_URL: "https://localhost:44330", //ASP.NET
  //API_URL: "http://localhost:5000", //Nodejs

  API_URL : "https://api.colive.com/qa", //qa-cbox-data
  //API_URL: "https://api.colive.com/live", //live-cbox-data

  API_URL_FORM: "https://api.propex.ai/web/live", //live-form
 // API_URL_FORM: "https://api.propex.ai/qa", //qa-form

    // AUTH_ID:"CAEA62B3-5F00-484B-A2B7-6A867B57DFC1", //live
    AUTH_ID: "4EF7BB12-F702-4AC5-B147-ACEEFD984AD3", //qa

  //UTILITY_URL: "https://www.propverse.ai/utility/assets", //live
   UTILITY_URL: "http://qa.propverse.ai/utility/assets", //qa

 
  ENQUIRY_API_URL: function () {
    return this.API_URL_FORM + "/api/Propverse/LandingPageLeadInsert"
  },
  SCHEDULE_API_URL: function () {
    return this.API_URL_FORM + "/api/Propverse/LandingPageLeadInsert"
  },
  TIME_API_URL: function () {
    return this.API_URL_FORM + "/api/Propverse/GetCheckInTimes"
  },
  DEV_PROJ_API_URL: function () {
    return this.API_URL + "/api/PropverseExternal/GetDeveloperProjectDetails"
  },
  LEAD_SOURCE_ENQUIRY: "PVWebEnquiry",
  LEAD_SOURCE_SCHEDULE: "PVWebSchedule",
  LANDING_PAGE_ID: 40,
  PLATFORM_ID: 24,
}

export default configs
